import { RequestUtil } from '../../utils';
import { CartDto } from '../../context/dto';
import { ProductDto } from '../products/dto/product-dto';
import { PubSubEnum } from '../../enums/pub-sub.enum';
import { IUIToast } from '../toast/toast.ui';

export class CartUtils {
  static async addToCart(
    cart?: CartDto, product?: ProductDto, qty?: number, setCart?: Function) {
    try {
      const cartDto = await RequestUtil.patch(`/cart/${cart?.uuid}`,
        {items: [{uuid: product?.uuid, qty}]}, {}, () => CartDto).
        then(response => {
          PubSub.publish(PubSubEnum.TOAST_SAVED,
            {message: 'Saved'} as IUIToast);
          return response;
        });
      setCart && setCart(cartDto);
    } catch (error: any) {
      PubSub.publish(PubSubEnum.TOAST_ERROR,
        {
          message: error?.response?.data?.message ||
            'Error adding product to cart',
        } as IUIToast);
      return console.error(error);
    }
  }

  static async cartPaid(cartPaidUUID: string) {
    return RequestUtil.get(`/cart/${cartPaidUUID}/paid`, {}, () => CartDto);
  }
}