import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PupSub from 'pubsub-js';
import { AppContext, IAppContext } from '../../context';
import { CheckoutTopicsConstants } from './checkout-topics-constants';
import useListCountries from '../../hooks/countries/use-list-countries.hook';
import useListCountryStates
  from '../../hooks/countries/use-list-country-states.hook';
import { CartDto } from '../../context/dto';
import {
  ShippingMethodsEnum
} from '../../hooks/shipping-methods/enum/shipping-methods.enum';

const CheckoutBillingAddress = () => {
  const {cart, setCart} = useContext(AppContext) as IAppContext;
  const formRef = useRef(null);
  const [formClasses, setFormClasses] = useState('needs-validation');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [country, setCountry] = useState('USA');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [showFullAddress, setShowFullAddress] = useState(false);
  
  const listCountries = useListCountries();
  const listStates = useListCountryStates({isoCode3: country}, [country]);

  const checkForm = useCallback(() => {
    const $form = formRef.current as unknown as any;
    if (!$form) return;
    $form.checkValidity();
    if (!$form.classList.contains('was-validated')) {
      setFormClasses(`${$form.classList} was-validated`);
    }
  }, [formRef]);

  // Subscriber
  useEffect(() => {
    PupSub.unsubscribe(CheckoutTopicsConstants.CHECKOUT_PAYMENT_CHECK_BILLING_ADDRESS_FORM);
    PupSub.subscribe(
      CheckoutTopicsConstants.CHECKOUT_PAYMENT_CHECK_BILLING_ADDRESS_FORM,
      checkForm);
  }, []);

  useEffect(() => {
    if (cart) {
      setCart && setCart({
        ...cart,
        billingAddress: {
          firstName,
          lastName,
          email,
          phone,
          addressLine1,
          addressLine2,
          country,
          city,
          state,
          zipCode,
        }
      } as CartDto);
    }
  }, [
    firstName,
    lastName,
    email,
    phone,
    addressLine1,
    addressLine2,
    country,
    city,
    state,
    zipCode]);

  useEffect(() => {
    if (!cart?.shipping?.code) {
      return;
    }
    setShowFullAddress(cart?.shipping?.code !== ShippingMethodsEnum.IN_STORE);
  }, [cart]);

  const onSubmitHandler = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      event.stopPropagation();
    }, [
      cart,
      firstName,
      lastName,
      email,
      phone,
      addressLine1,
      addressLine2,
      country,
      city,
      state,
      zipCode]);

  return (
    <>
      <form ref={formRef}
            className={formClasses}
            noValidate
            onSubmit={onSubmitHandler}>
        <fieldset>
          <legend>Contact Information</legend>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label htmlFor="firstName">First name *</label>
              <input type="text"
                     className="form-control"
                     id="firstName"
                     placeholder=""
                     value={firstName}
                     onChange={(evt) => setFirstName(evt.target.value)}
                     required/>
              <div className="invalid-feedback">
                This is required.
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="lastName">Last name
                <span className="text-muted">(Optional)</span></label>
              <input type="text"
                     className="form-control"
                     id="lastName"
                     placeholder=""
                     value={lastName}
                     onChange={(evt) => setLastName(evt.target.value)}/>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 mb-3">
              <label htmlFor="email">Email *</label>
              <input type="email"
                     className="form-control"
                     id="email"
                     placeholder="you@example.com"
                     value={email}
                     onChange={(evt) => setEmail(evt.target.value)}
                     required/>
              <div className="invalid-feedback">
                This is required.
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="phone">Phone
                <span className="text-muted">(Optional)</span></label>
              <input type="text"
                     className="form-control"
                     id="phone"
                     placeholder=""
                     value={phone}
                     onChange={(evt) => setPhone(evt.target.value)}/>
            </div>
          </div>
        </fieldset>

        {showFullAddress && (
          <fieldset>
            <legend>Address</legend>
            <div className="mb-3">
              <label htmlFor="address">Line 1 *</label>
              <input type="text"
                     className="form-control"
                     id="address"
                     placeholder="1234 Main St"
                     value={addressLine1}
                     onChange={(evt) => setAddressLine1(evt.target.value)}
                     required/>
              <div className="invalid-feedback">
                This is required.
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="address2">Line 2 <span className="text-muted">(Optional)</span></label>
              <input type="text"
                     className="form-control"
                     id="address2"
                     placeholder="Apartment or suite"
                     value={addressLine2}
                     onChange={(evt) => setAddressLine2(evt.target.value)}/>
            </div>

            <div className="row">
              <div className="col-md-5 mb-3 d-none">
                <label htmlFor="country">Country *</label>
                <select className="form-select d-block w-100"
                        id="country"
                        value={country}
                        onChange={(evt) => setCountry(evt.target.value)}
                        required>
                  <option value="">Choose...</option>
                  {
                    listCountries?.map(
                      country => <option key={country.isoCode3}
                                         value={country.isoCode3}>{country.name}</option>) ||
                      <></>
                  }
                </select>
                <div className="invalid-feedback">
                  This is required.
                </div>
              </div>
              <div className="col-md-5 mb-3">
                <label htmlFor="city">City</label>
                <input type="text"
                       className="form-control"
                       id="city"
                       placeholder=""
                       value={city}
                       onChange={(evt) => setCity(evt.target.value)}
                       required/>
                <div className="invalid-feedback">
                  This is required.
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="state">State *</label>
                <select className="form-select d-block w-100"
                        id="state"
                        value={state}
                        onChange={(evt) => setState(evt.target.value)}
                        required>
                  <option value="">Choose...</option>
                  {
                    listStates?.map(
                      state => <option key={state.isoCode}
                                       value={state.isoCode}>{state.name}</option>) ||
                      <></>
                  }
                </select>
                <div className="invalid-feedback">
                  This is required.
                </div>
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="zip">Zip *</label>
                <input type="text"
                       className="form-control"
                       id="zip"
                       placeholder=""
                       value={zipCode}
                       onChange={(evt) => setZipCode(evt.target.value)}
                       required/>
                <div className="invalid-feedback">
                  This is required.
                </div>
              </div>
            </div>
          </fieldset>
        )}

        <div className="col-12 d-none">
          <button className="btn btn-primary"
                  type="submit">Submit form
          </button>
        </div>
      </form>
    </>
  );
};

export default CheckoutBillingAddress;
