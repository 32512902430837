import "reflect-metadata";
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CacheUtil, Pipes, Utils } from './utils';
import { RequestUtil } from './utils';
import { CartDto, StoreDto } from './context/dto';
import { Try } from './utils';
import { UserDto } from './context/dto';
import { StoreCmsPropertiesDto } from './context/dto';
import { IAppContext } from './context';

async function initialContext(): Promise<IAppContext> {
  const [currentStore, storeCMSProperties, user, cart] = await Promise.all([
    RequestUtil.get(`/`, {},
      () => StoreDto),
    RequestUtil.get(`/cms`, {},
      () => StoreCmsPropertiesDto,
      response => ({properties: response})),
    Try(() => {
      if (CacheUtil.cache('__jsession')) {
        return RequestUtil.get('/auth/whichUser', {}, () => UserDto);
      }
    }),
    Try(() => {
      const sharedCart = Utils.queryParam('cart');
      const cartToken = sharedCart || CacheUtil.cache('__cart');
      if (cartToken) {
        return RequestUtil.get(`/cart/${cartToken}`, {}, () => CartDto).
          catch(error => RequestUtil.post('/cart', {}, {}, () => CartDto));
      }
      return RequestUtil.post('/cart', {}, {}, () => CartDto);
    }),
  ]);
  if (!user) {
    CacheUtil.removeCache('__jsession');
  }
  if (cart) {
    // @ts-ignore
    CacheUtil.cache('__cart', cart.uuid);
  } else {
    const newCart = await RequestUtil.post('/cart', {}, {}, () => CartDto);
    // @ts-ignore
    CacheUtil.cache('__cart', newCart.uuid);
  }
  const store = Pipes.transform(StoreDto, storeCMSProperties) as StoreDto;
  store.uuid = currentStore.uuid;
  store.isSandbox = currentStore.isSandbox;
  return {store, user, cart};
}

initialContext().then((appContext: IAppContext) => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );
  root.render(
    <React.StrictMode>
      <App user={appContext.user} store={appContext.store} cart={appContext.cart}/>
    </React.StrictMode>,
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
