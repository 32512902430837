import { Expose, Transform } from 'class-transformer';
import { Utils } from '../../utils';

export class CartSummaryDto {
  @Expose()
  tax!: number;

  @Expose()
  @Transform(({obj: cart}: { obj: CartSummaryDto }) => {
    return Utils.formatNumber(cart.tax ?? 0);
  })
  taxFormatted?: string;
  
  @Expose()
  subtotal!: number;

  @Expose()
  @Transform(({obj: cart}: { obj: CartSummaryDto }) => {
    return Utils.formatNumber(cart.subtotal ?? 0);
  })
  subtotalFormatted?: string;
  
  @Expose()
  total!: number;

  @Expose()
  @Transform(({obj: cart}: { obj: CartSummaryDto }) => {
    return Utils.formatNumber(cart.total ?? 0);
  })
  totalFormatted?: string;
}
