import { Expose, Transform } from 'class-transformer';
import {
  IsEmail,
  IsNotEmpty,
  IsOptional,
  IsString,
  ValidateIf,
} from 'class-validator';

export class BillingAddressDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  firstName!: string;

  @Expose()
  @IsString()
  @IsOptional()
  lastName?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsEmail()
  email!: string;

  @Expose()
  @IsString()
  @IsOptional()
  phone?: string;
  
  @Expose()
  validateFullAddress: boolean = false; // in runtime
  
  @Expose()
  @IsString()
  @IsNotEmpty()
  @ValidateIf(self => self.validateFullAddress)
  addressLine1!: string;

  @Expose()
  @IsString()
  @IsOptional()
  addressLine2?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  country!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @ValidateIf(self => self.validateFullAddress)
  city!: string;
  
  @Expose()
  @IsString()
  @ValidateIf(self => self.validateFullAddress)
  state!: string;

  @Expose()
  @IsString()
  @ValidateIf(self => self.validateFullAddress)
  zipCode!: string;
}
