import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext, IAppContext } from '../../context';
import useListShippingMethods
  from '../../hooks/shipping-methods/use-list-shipping-methods.hook';
import { CartDto } from '../../context/dto';

const CheckoutShipping = () => {
  const {cart, setCart} = useContext(AppContext) as IAppContext;
  const listShippingMethods = useListShippingMethods();

  useEffect(() => {
    if (!listShippingMethods?.length) {
      return;
    }
    const [shipping] = listShippingMethods;
    if (cart) {
      cart.shipping = shipping;
      setCart!({
        ...cart,
      } as CartDto);
    }
  }, [setCart, listShippingMethods]);

  const onChangeShippingMethod = useCallback((evt: any) => {
    const shipping = listShippingMethods.find(s => s.uuid === evt.target.value);
    if (!shipping) {
      return;
    }
    cart!.shipping = shipping;
    setCart!({
      ...cart,
    } as CartDto)
  }, [cart, listShippingMethods]);

  return (
    <>
      <h4 className="mb-3">Choose Your Shipping Method</h4>
      <div className="d-block my-3">
        {
          listShippingMethods?.length > 0 && listShippingMethods?.map(
            (shippingMethod, index) => (
              <div key={shippingMethod.uuid}
                   className="form-check">
                <input id={shippingMethod.uuid}
                       name="shippingMethod"
                       type="radio"
                       className="form-check-input"
                       value={shippingMethod.uuid}
                       onChange={onChangeShippingMethod}
                       defaultChecked={index === 0}/>
                <label className="form-check-label"
                       htmlFor={shippingMethod.uuid}>{shippingMethod.name}</label>
              </div>
            )) ||
            <>Not Shipping Method available!</>
        }
      </div>
    </>
  );
};

export default CheckoutShipping;
