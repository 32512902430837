import CheckoutLayout from '../../../layouts/checkout.layout';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CartUtils } from '../../../components/cart/cart-utils';
import { CartDto } from '../../../context/dto';
import CartItemCheckout
  from '../../../components/cart/checkout/cart-item-checkout';

const CheckoutPaidPage = () => {
  const routeParams = useParams();
  const [cartPaidUUID, setCartPaidUUID] = useState('');
  const [cartPaid, setCartPaid] = useState({} as CartDto);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const {cartPaidUUID} = routeParams;
    setCartPaidUUID(cartPaidUUID || '');
  }, [routeParams]);

  useEffect(() => {
    if (!cartPaidUUID) {
      return;
    }
    setIsLoading(true);
    CartUtils.cartPaid(cartPaidUUID).then(cartPaid => {
      setCartPaid(cartPaid);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [cartPaidUUID]);

  return <CheckoutLayout>
    {cartPaid &&
        <div className="container pt-5 pb-5 vh-100">
          <div className="row">
            <div className="col-md-12 order-md-2 mb-4">
              <ul className="list-group mb-3">
                {cartPaid.items?.length >= 1 &&
                  cartPaid.items.map(item => <CartItemCheckout key={item.uuid}
                                                               item={item}/>)}
              </ul>
            </div>
            <div className="col-md-12 order-md-2 mb-4">
              <ul className="list-group mb-3">
                <li className="list-group-item d-flex justify-content-between">
                  <span>Tax</span>
                  <strong>{cartPaid.summary?.taxFormatted ?? 0}</strong>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <span>Subtotal</span>
                  <strong>{cartPaid.summary?.subtotalFormatted ?? 0}</strong>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <span>Total</span>
                  <strong>{cartPaid.summary?.totalFormatted ?? 0}</strong>
                </li>
              </ul>
            </div>
          </div>
        </div>
    }
  </CheckoutLayout>;
};

export { CheckoutPaidPage };